<template>
	<CHeader fixed with-subheader light>
		<!-- <img src="https://amarillasinfoguia.com/all_sites/img/site_desktop/logo-home.png"> -->
		<CToggler
			in-header
			class="ml-3 d-lg-none"
			@click="$store.commit('coreuiStore/toggleSidebarMobile')"
		/>
		<CToggler
			id="togglerMenuButton"
			in-header
			class="ml-3 d-md-down-none"
			@click="$store.commit('coreuiStore/toggleSidebarDesktop')"
			title="Ocultar/mostrar menú lateral"
			v-b-tooltip.hover.rightbottom.v-primary
		/>
		<CHeaderBrand class="mx-auto d-lg-none" to="/">
			<img :src="imgLogo" style="width: 70%;" />
		</CHeaderBrand>
		<!-- menu de accesos rapidos -->
		<CHeaderNav class="d-md-down-none mr-auto">
			<CHeaderNavItem style="font-weight:bold;" class="px-3">
				<!-- Hola {{this.userInfo.name}} aqui tienes algunos accesos rapidos -->
				Accesos rapidos
			</CHeaderNavItem>
			<CHeaderNavItem v-if="isAllowed" class="px-3">
				<CHeaderNavLink
					style="font-weight:bold;"
					to="/users/newUser"
					exact
				>
					Crear Usuario
				</CHeaderNavLink>
			</CHeaderNavItem>
			<CHeaderNavItem class="px-3">
				<CHeaderNavLink
					style="font-weight:bold;"
					to="/requests/requestAuth"
					exact
				>
					Solicitar Autorización
				</CHeaderNavLink>
			</CHeaderNavItem>
			<CHeaderNavItem class="px-3">
				<CHeaderNavLink
					style="font-weight:bold;"
					target="_blank"
					:href="urlFaq"
					exact
				>
					FAQ de InfoFeed
				</CHeaderNavLink>
			</CHeaderNavItem>
		</CHeaderNav>
		<CHeaderNav class="mr-4">
			<!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
        </CHeaderNavLink>
      </CHeaderNavItem> -->
			<TheHeaderDropdownAccnt />
		</CHeaderNav>
		<CSubheader class="px-3">
			<CBreadcrumbRouter class="border-0 mb-0" />
		</CSubheader>
	</CHeader>
</template>

<script>
	import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';
	import { userProfiles } from '../constants/profilesUsers';

	export default {
		name: 'TheHeader',
		components: {
			TheHeaderDropdownAccnt,
		},
		created() {
			if (this.rolesAllowed.includes(this.userInfo.id_profiles)) {
				this.isAllowed = true;
			}
		},
		data() {
			return {
				imgLogo: process.env.VUE_APP_LOGO_INFOGUIA,
				imgAvatar: process.env.VUE_APP_AVATAR_INFOGUIA,
				urlFaq: process.env.VUE_APP_URL_FAQ,
				userInfo: JSON.parse(sessionStorage.getItem('userData')),
				rolesAllowed: [
					userProfiles.adminUser,
					userProfiles.coordinator,
				],
				isAllowed: false,
			};
		},
	};
</script>
