import { userProfiles } from '../constants/profilesUsers';
export default [
	{
		_name: 'CSidebarNavItem',
		_children: [
			{
				_name: 'CSidebarNavItem',
				name: 'Dashboard',
				to: '/dashboard',
				fontIcon: 'fas fa-tachometer-alt',
			},
		],
	},
	{
		_name: 'CSidebarNavItem',
		name: 'FAQ de InfoFeed',
		href: process.env.VUE_APP_URL_FAQ,
		fontIcon: 'fas fa-book',
		_class: 'bg-info text-white',
		target: '_blank'
	},
	// administracion del sistema
	{
		_name: 'CSidebarNavTitle',
		_children: ['Administración del sistema'],
		roles: [userProfiles.adminUser, userProfiles.coordinator],
	},
	{
		_name: 'CSidebarNavDropdown',
		name: 'Usuarios',
		route: '/users',
		fontIcon: 'fas fa-users',
		roles: [userProfiles.adminUser, userProfiles.coordinator],
		items: [
			{
				name: 'Nuevo Usuario',
				to: '/users/newUser',
				//fontIcon: 'fas fa-user-plus',
			},
			{
				name: 'Consultar Usuarios',
				to: '/users/consult',
				//fontIcon: 'fas fa-address-book',
			},
		],
	},
	// funciones del sistema
	{
		_name: 'CSidebarNavTitle',
		_children: ['Funciones del sistema'],
	},
	{
		_name: 'CSidebarNavDropdown',
		name: 'Administrar Widgets',
		route: '/requests',
		fontIcon: 'fas fa-tasks',
		items: [
			{
				name: 'Solicitar Autorización',
				to: '/requests/requestAuth',
				//fontIcon: 'fas fa-paper-plane',
			},
			{
				name: 'Por Autorizar',
				to: '/requests/pendigAuth',
			},
			{
				name: 'Historial',
				to: '/requests/historyAuths',
				//fontIcon: 'fas fa-history',
			},
		],
	},
	{
		_name: 'CSidebarNavDropdown',
		name: 'Reportes',
		route: '#',
		fontIcon: 'fas fa-chart-line',
		items: [
			{
				name: 'Clientes Sin Widget',
				to: '/reports/withoutWidget',
			},
			{
				name: 'Solicitudes Enviadas',
				to: '/reports/requestsSent',
			},
		],
	},
];
