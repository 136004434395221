<template>
	<b-modal
		v-model="showModal"
		centered
		no-close-on-backdrop
		no-close-on-esc
		size="lg"
		header-bg-variant="info"
		header-text-variant="light"
	>
		<CRow>
			<CCol>
				<p>Tu sesi&oacute;n ha expirado.</p>
				<p>
					Si lo deseas puedes renovar tu sesi&oacute;n por
					<b>{{ tokenExpiresIn }}</b> m&aacute;s haciendo clic en el
					bot&oacute;n <b>Renovar</b>. De lo contrario puedes cerrar
					la sesi&oacute;n haciendo clic en el bot&oacute;n
					<b>Salir</b>.
				</p>
				<p>
					Esta modal se cerrar&aacute; en <b>{{ second }}</b> segundos
					y al hacerlo se cerrar&aacute; tu sesi&oacute;n en el
					sistema.
				</p>
			</CCol>
		</CRow>
		<template v-slot:modal-header>
			<h6 class="modal-title">Sesi&oacute;n Expirada!!</h6>
		</template>
		<template v-slot:modal-footer>
			<b-button
				@click="logOut"
				pill
				variant="warning"
				class="mr-1 text-white"
				>Salir
			</b-button>
			<b-button
				@click="refresh"
				pill
				variant="success"
				class="mr-1 text-white"
				>Renovar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: 'modalSessionExpired',
		props: {
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
		},
		data() {
			return {
				thirtySecondsToClose: 30000,
				showModal: this.triggerModal,
				userTokens: null,
				tokenExpiresIn: null,
				clearTimer: false,
			};
		},
		created() {
			this.getUserTokens;
			this.tokenExpiresIn = this.$moment
				.duration(this.getUserTokens.expires_in, 'seconds')
				.humanize();
			this.timerSession();
		},
		computed: {
			second() {
				return this.thirtySecondsToClose / 1000;
			},
			getUserTokens: function() {
				return (this.userTokens = JSON.parse(
					sessionStorage.getItem('userTokens')
				));
			},
		},
		methods: {
			timerSession() {
				let timerId = setInterval(() => {
					this.thirtySecondsToClose -= 1000;
					if (this.clearTimer) clearInterval(timerId);
					if (this.thirtySecondsToClose < 1) {
						clearInterval(timerId);
						this.logOut();
					}
				}, 1000);
			},
			logOut() {
				this.$store
					.dispatch('auth/logout')
					.then((response) => {
						this.clearTimer = true;
						this.$router.push({
							name: 'Login',
						});
					})
					.catch((error) => {
						console.log(error.response);
						this.clearTimer = true;
						this.$router.push({
							name: 'Login',
						});
					});
			},
			refresh() {
				this.$store
					.dispatch('auth/refreshTokenTest')
					.then((response) => {
						this.clearTimer = true;
						this.$emit('refresh', response.expires_in);
						//this.showModal = false;
					})
					.catch((error) => {
						console.log(error);
					});
			},
		},
	};
</script>

<style></style>
