<template>
	<div class="c-app">
			<modal-session-expired
				v-if="sessionExpire"
				:trigger-modal="true"
				@refresh="refreshSession"
			/>
		<TheSidebar />
		<CWrapper>
			<TheHeader />
			<div class="c-body">
				<main class="c-main">
					<CContainer fluid>
						<transition name="fade" mode="out-in">
							<router-view :key="$route.path"></router-view>
						</transition>
					</CContainer>
				</main>
			</div>
			<TheFooter />
		</CWrapper>
	</div>
</template>

<script>
	import TheSidebar from './TheSidebar';
	import TheHeader from './TheHeader';
	import TheFooter from './TheFooter';
	import ModalSessionExpired from '../components/modals/modalSessionExpired';

	export default {
		name: 'TheContainer',
		components: {
			TheSidebar,
			TheHeader,
			TheFooter,
			ModalSessionExpired,
		},
		data() {
			return {
				userTokens: null,
				timerExpiration: process.env.VUE_APP_EXPIRATION_TIMER,
				sessionExpire: false,
				delaySeconds: 10,
			};
		},
		created() {
			this.getUserTokens;
			this.timerExpiration =
				this.userTokens.expires_in - this.delaySeconds;
		},
		methods: {
			refreshSession(expiresIn) {
				this.timerExpiration = expiresIn;
				this.sessionExpire = false;
			},
		},
		computed: {
			getUserTokens: function() {
				return (this.userTokens = JSON.parse(
					sessionStorage.getItem('userTokens')
				));
			},
		},
		watch: {
			timerExpiration: {
				handler(value) {
					if (value > 0) {
						setTimeout(() => {
							this.timerExpiration--;
						}, 1000);
					}
					if (value < 1) {
						this.sessionExpire = true;
					}
				},
				immediate: true,
			},
		},
	};
</script>

<style scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
